<template>
  <FocusTrap>
    <div class="card" id="payment_card">

      <div class="page-header page-header-light" >
        <div class="page-header-content header-elements-lg-inline" >
          <div class="page-title d-flex" style="padding: 9px;">
            <h6>
              <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Payment </span> - List
            </h6>
            <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
          </div>

          <div class="header-elements d-none">
            <form action="#">
              <div class="form-group">

                <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                  <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                  <div class="form-control-feedback">
                    <i class="icon-search4"></i>
                  </div>

                  <div class="input-group-append position-static">
                    <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterModal">
                      <i class="icon-gear"></i>
                    </button>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive">
          <table id="payment_table"
                 class="table table-bordered table-columned"
                 data-search="false"
                 data-pagination="false"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-formatter="runningFormatter" data-sortable="true" data-width="75" >S.No</th>
              <th data-width="100" data-field="vch_no" data-sortable="true" data-align="center">Doc No</th>
              <th data-width="107" data-field="doc_date" data-sortable="true" data-formatter="dateFormatter" >Date</th>
              <th data-field="list" data-formatter="ledger2RowFormatter" data-sortable="true">Description</th>
              <th data-field="remarks" data-sortable="true">Narration</th>
              <th data-field="amount" data-width="200" data-align="right" data-sortable="true" data-formatter="indianFormat">Amount</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>

        <!-- Context Menu -->
        <div id="context-menu" class="context-table">
          <div class="dropdown-menu">
            <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
            <div class="dropdown-divider"></div>
<!--            <a href="#" class="dropdown-item"> <i class="icon-move" ></i>Move</a>-->
            <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
          </div>
        </div>
        <!-- End of Context Menu -->


        <vmodal name="payment_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" :height="475" @before-open="beforeOpen" @before-close="beforeClose" >
          <PaymentForm v-on:payment_window_closed="closeModal" v-bind:myvoucher="voucher" v-on:payment_updated="loadData" ></PaymentForm>
        </vmodal>

        <vmodal name="ibr-move-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="250" height="300" >
          <!-- Form -->
          <form class="modal-body form-validate" action="#">
            <div class="text-center mb-3">
              <i class="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
              <h5 class="mb-0">Choose Branch to be Move</h5>
            </div>

            <div class="form-group form-group-feedback form-group-feedback-left">
              <select class="form-control" v-model="selected_ibr">
                <option v-for="brn in branches" v-bind:value="brn.code">
                  {{ brn.name }}
                </option>
              </select>
              <div class="form-control-feedback">
                <i class="icon-box text-muted"></i>
              </div>
            </div>


            <div class="form-group">
              <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple btn-block" @click="moveToIBR">Move <i class="icon-move-right ml-2"></i></button>
            </div>

          </form>
          <!-- /form -->

        </vmodal>

      </div>

      <FocusTrap>
        <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="250" @opened="afterFilterWindowOpen">
          <form>
            <div class="card">
              <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
                <h3 class="card-title">Filter</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input  ref= "txtfrom" id="txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" autofocus />
                  </div>

                  <label class="col-md-3 col-form-label">Upto</label>
                  <div class="col-md-3">
                    <input  ref="txtupto" type="date" class="form-control"  v-model="upto_date" :min="from_date" />
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Ledger:</label>
                  <div class="col-md-9">
                    <select  ref="cmbLedger" id="cmbLedger" class="form-control" v-model="ledger_id" >
                      <option value="0" selected>All</option>
                      <option v-for="camp in ledgers" v-bind:value="camp.id">
                        {{ camp.name }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>
    </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import PaymentForm from './PaymentForm';
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  //https://euvl.github.io/vue-js-modal/Installation.html
  export default {
    name: 'PaymentView',
    components: {
      PaymentForm
    },
    store,
    data () {
      return {
        mytable: {},
        voucher: JSON.parse('{"id":0,"status":"A","type":0,"vch_no":1,"finyear":2000,"vch_type":1,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","cmp_id":1,"ledger":{"id":1,"name":""},"list":[]}'),
        branches :[],
        ledgers : [],
        selected_ibr : 1,
        voucher_id: 0,
        ledger_id : 0,
        from_date : moment().format('YYYY-MM-DD'),
        upto_date : moment().format('YYYY-MM-DD'),
      }
    },
    created () {

    },
    mounted () {
      const self = this;

      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

      self.$data.mytable = $('#payment_table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      $('#payment_table').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyVoucher(id);
          }else if ($(e.target).text() === 'Cancel') {
            self.cancelVoucher(id);
          }else if ($(e.target).text() === 'Move') {
            self.$data.voucher_id = id;
            self.$modal.show('ibr-move-window');
          }

        }
      });

      $('#txtsearch').keyup(function () {

        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

        $('#payment_table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide();
      });

      //self.loadData();
      self.$data.from_date = moment().format('YYYY-MM-DD');
      self.$data.upto_date = moment().format('YYYY-MM-DD');

      self.$modal.show('filter-window');
    },
    methods:{
      showModal () {
        this.$modal.show('payment_window');
      },
      showFilterModal () {
        this.$modal.show('filter-window');
      },
      afterFilterWindowOpen(){
        this.$refs.txtfrom.focus();
      },
      beforeOpen(){

      },
      beforeClose(){

      },
      closeModal () {
        this.$modal.hide('payment_window');
      },
      loadData(){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          credentials: 'same-origin',
          headers: userService.authHeader()
        };


        this.$modal.hide('payment_window');
        this.$modal.hide('filter-window');

        $('#txtsearch').val('');

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.mytable.bootstrapTable('load',[]);
        self.$data.mytable.bootstrapTable('showLoading');


        fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevouchers/vchtype/102/between?from=${self.$data.from_date}&upto=${self.$data.upto_date}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if( _.isArray (resp.data)) {
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          self.$data.mytable.bootstrapTable('hideLoading');
          $(self.$data.mytable).unblock();
        });

      },
      modifyVoucher(id){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        try {
          self.$data.voucher = JSON.parse('{"id":0,"status":"A","type":0,"vch_no":1,"finyear":2000,"vch_type":1,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","cmp_id":1,"ledger":{"id":1,"name":""},"list":[]}');
          self.$data.voucher.id = id;
          fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevoucher/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok) {

              self.$data.voucher = resp.data;
              self.$data.voucher.doc_date = resp.data.doc_date;

              self.$modal.show('payment_window');
            } else {
              swal({ title: "Oops!", text: resp.msg, type: "error" });
            }
          }).catch(function (err) {
            swal({ title: "Oh noes!", text: err.toString(), type: "error" });
          });
        }catch (e) {
          alert(e);
        }

      },
      cancelVoucher (id) {
        const self = this;

        self.$data.voucher.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.voucher)
        };

        swal({
          title: 'What is the reason for cancel this voucher?',
          input: 'text',
          inputPlaceholder: 'Enter Remarks here',
          showCancelButton: true,
          inputClass: 'form-control',
          inputValidator: function(value) {
            return !value && 'You need to write something!'
          }
        }).then(function(result) {
          if(result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevoucher/${id}?remarks=${result.value}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('payment_window');
              if(resp.ok){
                self.loadData();
                swal({
                  title: 'Canceled!',
                  text: 'Your request has been processed',
                  type: 'success'
                });
              }else {
                swal({ title: "Oops", text: resp.msg, type: "info" });
              }

            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });
      },
    }
  }

</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
